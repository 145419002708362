import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/services/services-details1.jpg'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Digital transformation" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="Digital transformation" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Digital transformation</span>
                            <h3>Smart Organisations</h3>
                            <p>We support your organization towards an efficient and effective use of technology, through consultancy, training and the development of the IT solutions that best fit your needs. </p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Smart working</li>
                                            <li>Collaboration</li>
                                            <li>Process digitalisation</li>
                                            <li>Capacity building</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>The digitalization of work organization can bring enormous advantages in terms of quality of life for workers, but also in terms of efficiency of production processes. Working in "smart" mode is much more than making calls. For this reason, our support involves the analysis of internal processes, the training of human resources and the identification of the most appropriate tools for the specific context. 
</p>

 

                            <h3>Tecnologies</h3>
                            <ul className="technologies-features">
                                <li><span>Google Workspace</span></li>
                                <li><span>Office365</span></li>
                                <li><span>Thinkific</span></li>
                                <li><span>Moodle</span></li>
                                <li><span>Opigno</span></li>
                                <li><span>Slack</span></li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/app-development" >
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation" className="active">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/case-management">
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/cooperazione-digitale">
                                   Cooperazione Digitale 
                                </Link>
                            </h3>
                            <p>Supporting your organization towards an efficient and effective use of technology</p>

                            <Link to="/project/cooperazione-digitale" className="read-more-btn">
                                Read more<i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/formazionesviluppo">
                                    FormazioneSviluppo
                                </Link>
                            </h3>
                            <p>Capacity building in the project "Innovazione per Lo sviluppo"</p>

                            <Link to="/project/formazionesviluppo" className="read-more-btn">
                                Read more<i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Details
